#home {
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  overflow: hidden;
  padding: 0 3rem;
  color: white;

  &:before {
    content: "";
    position: absolute;
    background: url("../images/landing.jpeg") no-repeat center center/cover;
    filter: grayscale(40%);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .profileImage {
    position: relative;
    height: 70%;
    width: 30%;
    border-radius: 5px;
    background: url("../images/profile-photo.jpg") no-repeat center center/cover;

    &:before {
      content: "";
      position: absolute;
      top: -5%;
      left: 5%;
      height: 110%;
      width: 100%;
      background: rgba(211, 211, 211, 0.65);
      border-radius: 5px;
      z-index: -1;
    }
  }

  .content {
    height: 90%;
    width: 65%;
    position: relative;
    top: -2.5%;
    left: 2.5%;
    // background: white;

    .subNav {
      height: 10%;
      width: 100%;

      button {
        all: unset;
        color: black;
        height: 100%;
        width: 35%;
        background: rgba(211, 211, 211, 0.8);
        border-radius: 5px;
        margin: 0 0.25rem;

        &:hover,
        &.active {
          cursor: pointer;
          color: white;
          background: black;
        }
      }
    }

    .tabs {
      height: 90%;
      position: relative;

      .card {
        height: 90%;
        width: 90%;
        border-radius: 5px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .card:before {
        content: "";
        position: absolute;
        top: 3.5%;
        left: 3.5%;
        width: 93%;
        height: 93%;
        border: 2px solid black;
        z-index: -1;
      }

      .card:nth-child(1) {
        background: rgb(244, 83, 66);
        z-index: 2;
        box-shadow: 5px 10px rgba(136, 136, 136, 0.3);
        top: 5%;
        left: 5%;

        .scroll {
          display: none;
        }

        p {
          height: 87.5%;
          width: 87.5%;
          max-height: 87.5%;
          overflow-y: auto;
          font-size: 1rem;
          line-height: 1.5rem;
          text-align: initial;
          color: white;

          a {
            color: rgb(63, 62, 62);
            font-weight: bold;
            text-decoration: none;

            &:hover {
              color: black;
              cursor: pointer;
            }
          }
        }
      }

      .card:nth-child(2) {
        display: flex;
        flex-direction: column;
        background: rgb(207, 207, 207);
        top: 7.5%;
        left: 7.5%;

        .key {
          height: 25%;
          width: 70%;
        }

        .skillList {
          height: 60%;
          width: 90%;
          list-style-type: none;

          li {
            display: inline-block;
            margin: 1px 1px;
            padding: 1rem;
            font-size: 0.85rem;
            color: white;
            background: black;
            border: 0.5px solid rgb(175, 174, 174);
            border-radius: 5px;

            &.workingKnowledge {
              background: rgb(244, 83, 66);
              color: white;
            }
          }
        }
      }
    }
  }
}

// Media Queries
@media only screen and (max-width: 1430px) {
  #home {
    .content {
      .tabs {
        .card:nth-child(1) {
          p {
            font-size: 0.9rem;
            line-height: 1.4rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  #home {
    .content {
      .tabs {
        .card:nth-child(1) {
          p {
            font-size: 0.85rem;
            line-height: 1.4rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1130px) {
  #home {
    .content {
      .tabs {
        .card:nth-child(1) {
          p {
            font-size: 0.8rem;
            line-height: 1.3rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  #home {
    .content {
      .tabs {
        .card:nth-child(1) {
          p {
            font-size: 0.85rem;
            line-height: 1.3rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  #home {
    .profileImage {
      display: none;
    }

    .content {
      width: 100%;

      .tabs {
        .card:nth-child(1) {
          flex-direction: column;

          .scroll {
            display: block;
            height: 5%;
            color: rgb(63, 62, 62);
          }

          p {
            max-height: 77.5%;
            padding: 5px;
            font-size: 1rem;
            line-height: 1.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 630px) {
  #home {
    .content {
      .tabs {
        .card:nth-child(1) {
          p {
            font-size: 1.2rem;
            line-height: 1.8rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  #home {
    .content {
      .tabs {
        .card:nth-child(2) .skillList {
          width: 80%;

          li {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}
