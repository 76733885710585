// Variables
$primary-color: rgba(217, 48, 79, 0.8);
$overlay-color: rgba(166, 3, 3, 0.9);
$menu-speed: 1s;

#portfolio {
  position: relative;

  //Menu Styles
  nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    .toggler {
      position: absolute;
      top: 0;
      left: 0;
      height: 70px;
      width: 70px;
      z-index: 1000;
      opacity: 0;
      cursor: pointer;
    }

    .hamburger {
      position: absolute;
      top: 0;
      left: 0;
      height: 70px;
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $primary-color;
      padding: 1rem;
      z-index: 999;

      > div {
        height: 3px;
        width: 100%;
        background: #fff;
        position: relative;
        transition: all 0.4s ease;

        /* Hamburger Lines - Top & Bottom */
        &:before,
        &:after {
          content: "";
          position: absolute;
          z-index: 999;
          top: -10px;
          width: 100%;
          height: 3px;
          background: inherit;
        }

        /* Hamburger Lines - Move Bottom Down*/
        &:after {
          top: 10px;
        }
      }
    }

    .menu {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      visibility: hidden;
      overflow: hidden;

      .menu-overlay {
        background: $overlay-color;
        width: 70px;
        height: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .menu-content {
          text-align: center;
          max-width: 90vw;
          max-height: 70vh;
          opacity: 0;

          > ul > li {
            list-style: none;
            color: #fff;
            font-size: 1.5rem;
            padding: 1rem;
            position: relative;
            line-height: 4rem;

            &:hover {
              font-size: 2rem;
            }

            > a {
              color: inherit;
              text-decoration: none;
              transition: color 0.6s ease;
            }

            &:before,
            &:after {
              opacity: 0;
              position: absolute;
              height: 2px;
              width: 0%;
              background: white;
              transition: all 0.3s;
              content: "";
            }

            &:before {
              left: 0px;
              top: 0px;
            }

            &:after {
              right: 0px;
              bottom: 0px;
            }

            &:hover:before,
            &:hover:after {
              opacity: 1;
              width: 100%;
            }
          }

          .downloadIcon {
            margin: 0 10px;
          }
        }
      }
    }
  }

  // Toggle Animation
  nav {
    .toggler:checked + .hamburger > div {
      transform: rotate(135deg);
    }

    .toggler:checked + .hamburger > div:before,
    .toggler:checked + .hamburger > div:after {
      top: 0;
      transform: rotate(90deg);
    }

    .toggler:checked:hover + .hamburger > div {
      transform: rotate(225deg);
    }

    /* Show Menu */
    .toggler:checked ~ .menu {
      visibility: visible;
    }

    .toggler:checked ~ .menu .menu-overlay {
      animation: menuExpansion $menu-speed ease forwards;
    }

    .toggler:checked ~ .menu > div > div {
      transition: opacity 1s ease $menu-speed;
      opacity: 1;
    }
  }

  .upArrowContainer {
    height: 15%;
    width: 15%;
    position: fixed;
    bottom: 1.5%;
    right: -2.5%;
    z-index: 998;
    opacity: 0.5;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    .upArrow {
      fill: rgb(217, 48, 79);
    }
  }
}

// Media Queries
@media only screen and (max-width: 1000px) {
  #portfolio {
    .upArrowContainer {
      bottom: 1.5%;
      right: -1.5%;
    }
  }
}

@media only screen and (max-width: 850px) {
  #portfolio {
    .upArrowContainer {
      right: 0;
    }
  }
}

@media only screen and (max-width: 650px) {
  #portfolio {
    nav {
      .menu {
        .menu-overlay {
          .menu-content {
            > ul > li {
              font-size: 2.25rem;
              line-height: 5.5rem;

              &:hover {
                font-size: 3rem;
              }
            }
          }
        }
      }
    }

    .upArrowContainer {
      right: 1.5%;
    }
  }
}

@media only screen and (max-width: 450px) {
  #portfolio {
    .upArrowContainer {
      right: 3.5%;
    }
  }
}

@keyframes menuExpansion {
  40% {
    height: 100vh;
    width: 70px;
  }

  100% {
    width: 100vw;
    height: 100vh;
  }
}
